import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from '../../components/Landing/AppAppBar';
import Hero from '../../components/Landing/Hero';
import LogoCollection from '../../components/Landing/LogoCollection';
import Highlights from '../../components/Landing/HighLights';
import Features from '../../components/Landing/Features';
import Testimonials from '../../components/Landing/Testimonials';
import Footer from '../../components/Landing/Footer';
import Midia from '../../components/Landing/Media';
import Services from '../../components/Landing/Services';
import CallToAction from '../../components/Landing/CallToAction';
import getLPTheme from './styled';


export default function LandingPage() {
  const [mode, setMode] = React.useState('light');
  const LPtheme = createTheme(getLPTheme(mode));
  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  React.useEffect(() => {
    document.body.style.overflowX = 'hidden';
    document.body.style.maxWidth = '100%';
  
    return () => {
      document.body.style.overflowX = 'auto';
      document.body.style.maxWidth = 'none';
    };
  }, []);

  // Componente do Divider personalizado

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Box sx={{
          bgcolor: 'background.default',
          width: '100vw', // Garante que o Box ocupe toda a largura da tela
          overflowX: 'hidden', // Evita a rolagem horizontal indesejada
        }}>

        <Features />
        <Services />
        <Highlights />
        <LogoCollection />   
        <Testimonials /> 
        <Midia />
        <CallToAction />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
