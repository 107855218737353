import React from 'react'; 
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Fundo from '../../assets/img/FundoBlackBeltITSolutionsN.jpg';
import Comeia from '../../assets/img/Comeia.svg';
import ComeiaB from '../../assets/img/ComeiaB.svg';

export default function Hero() {
  const { ref: textRef, inView: textInView } = useInView({ triggerOnce: true });
  const { ref: logoRef, inView: logoInView } = useInView({ triggerOnce: true });

  return (
    <Box
      id="hero"
      sx={{
        position: 'relative',
        backgroundColor: 'black',
        width: '100%',
        height: '100vh',
        pt: { xs: 4, sm: 8 },
        pb: { xs: 8, sm: 16 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <img 
        src={ComeiaB} 
        alt="Logo" 
        style={{ 
          width: '15%', // Ajuste o tamanho conforme necessário
          height: 'auto', 
          position: 'absolute',
          marginTop: '10%', 
          top: '0', 
          left: '0', 
          opacity: 0.4, 
          zIndex: 2, // Garante que fique acima do fundo
          animation: 'distortionEffect 10s ease-in-out infinite',
        }} 
      />

      <img 
        src={Comeia} 
        alt="Logo" 
        style={{ 
          width: '15%', 
          height: 'auto', 
          position: 'absolute', 
          bottom: '0', 
          right: '0',
          opacity: 0.4, 
          zIndex: 2, // Para garantir que fique sobre o fundo
          animation: 'distortionEffect 10s ease-in-out infinite',
        }} 
      />

      <style>
        {`
          @keyframes distortionEffect {
            0% { 
              transform: scale(1) skew(0deg, 0deg) rotate(0deg); 
              filter: blur(0px); 
            }
            25% { 
              transform: scale(1.05) skew(5deg, 3deg) rotate(15deg); 
              filter: blur(0.5px); 
            }
            50% { 
              transform: scale(1.1) skew(-5deg, 4deg) rotate(-10deg); 
              filter: blur(1px); 
            }
            75% { 
              transform: scale(1.03) skew(3deg, -6deg) rotate(5deg); 
              filter: blur(0.5px); 
            }
            100% { 
              transform: scale(1) skew(0deg, 0deg) rotate(0deg); 
              filter: blur(0px); 
            }
          }
        `}
      </style>

      {/* Barra branca na parte superior */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '30px',
          backgroundColor: 'white',
          zIndex: 3,
        }}
      />
      
      {/* Fundo animado */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${Fundo})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center top',
          animation: 'subtleDistortion 24s ease-in-out 1', // A animação agora é mais lenta
          zIndex: 1,
          opacity: 0.4,
          '@keyframes subtleDistortion': {
            '0%': { transform: 'scale(1) skew(0deg, 0deg) rotate(0deg)', filter: 'blur(0px)', opacity: 0.4 },
            '50%': { transform: 'scale(1.1) skew(-0.5deg, 0.5deg) rotate(-0.2deg)', filter: 'blur(1px)', opacity: 0.45 },
            '100%': { transform: 'scale(1) skew(0deg, 0deg) rotate(0deg)', filter: 'blur(0px)', opacity: 0.4 },
          },
        }}
      />

      <Container
      id='Hero'
         sx={{
          zIndex: '1 !important',
          py: { xs: 7, sm: 10 },
          maxWidth: { xs: '90%', sm: '80%', md: '70%' }, // Reduzindo a largura para mover à direita
          padding: '0',
          minHeight: '100vh',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column', // Alinhando os elementos verticalmente
          alignItems: 'start',
          justifyContent: 'center',
          textAlign: 'end',
          ml: { xs: '5%', sm: '10%', md: '15%' }, // Movendo para a direita
        }}
        
      >
        
        {/* Título animado */}
        <motion.div
          ref={textRef}
          initial={{ opacity: 0, y: 50 }}
          animate={textInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, ease: 'easeOut' }}
        >
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{
              textAlign: 'left',
              width: '120%',
              mb: { xs: 3, sm: 2 },
              fontSize: { xs: '40px', sm: '52px', md: '58px', lg: '70px', xl: '100px' },
              fontFamily: 'Rubik, sans-serif',
              color: 'white',
              marginTop: '30px',
            }}
          >
            Protegendo sua empresa <br />
            com estratégia
          </Typography>
        </motion.div>

        {/* Logo animada */}
        <motion.div
          ref={logoRef}
          initial={{ opacity: 0, y: 50 }}
          animate={logoInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, ease: 'easeOut', delay: 0.2 }}
        >
          <Box
            sx={{
              width: { xs: '70%', sm: '50%', md: '40%' }, // Ajuste responsivo da logo
              mt: { xs: 3, sm: 4 }, // Espaço entre o texto e a logo
            }}
          >
            <Button
              id="SaibaMais"
              style={{ fontFamily: 'Rubik, sans-serif', zIndex: '2' }}
              variant="contained"
              color="primary"
              href="https://api.whatsapp.com/send/?phone=558184393531&text&type=phone_number&app_absent=0"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                mt: 2,
                fontSize: { xs: '16px', sm: '22px' },
                height: 'auto',
                padding: { xs: '12px 24px', sm: '16px 32px' },
                width: { xs: '150%', sm: '250px' }, // 100% em telas pequenas, fixo em telas maiores
                maxWidth: '400px', // Limita a largura máxima em telas grandes
                alignSelf: 'center', // Centraliza o botão quando for 100%
                '&:hover': {
                  backgroundColor: '#8b0e00',
                  color: 'white',
                },
              }}
            >
              Saiba Mais
            </Button>
          </Box>
        </motion.div>
      </Container>
    </Box>
    
  );
}
