import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Logo from '../../assets/img/Comp.png';
import IconRede from '../../assets/img/RedeIcon.svg';
import Selo from '../../assets/img/SELO (2).svg';

const logoStyle = {
  width: '90%',
  height: 'auto',
  cursor: 'pointer',
  margin: '-80px auto',
};

const seloStyle = {
  width: '60%',
  height: 'auto',
  cursor: 'pointer',
  margin: '0 15%',
};

function Features() {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  return (
    <Container
      id="services"
      sx={{
        zIndex: '1 !important',
        py: { xs: 7, sm: 10 },
        backgroundColor: 'white',
        maxWidth: '100% !important',
        padding: 0,
        minHeight: '100vh',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: '0%',
          width: '100%',
          height: '100%',
          marginTop: '10%',
          backgroundImage: `url(${IconRede})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          animation: 'distortEffect 15s infinite alternate',
          zIndex: -1,
          opacity: 0.5,
          overflow: 'visible',
        }}
      />
      <style>
        {`
          @keyframes distortEffect {
            0% {
              transform: scale(1) skewX(0deg) skewY(0deg);
            }
            50% {
              transform: scale(1.05) skewX(5deg) skewY(3deg);
            }
            100% {
              transform: scale(1) skewX(0deg) skewY(0deg);
            }
          }
        `}
      </style>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              textAlign: { xs: 'center', md: 'left' },
              marginLeft: { xs: 0, md: '30%' }, // Ajuste condicional da margem
            }}
            style={{ zIndex: 2 }}
            ref={ref}  // Adicionando o ref para o observer
          >
            <motion.div
              initial={{ opacity: 0, y: 50 }}  // Inicia com opacidade 0 e movido para baixo
              animate={inView ? { opacity: 1, y: 0 } : {}}  // Quando entra em vista, volta à opacidade 1 e posição inicial
              transition={{ duration: 0.8, ease: 'easeOut' }}  // Animação suave
            >
              <a
                href="https://programas.sebraestartups.com.br/in/premiosebraestartups"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Selo} alt="Selo Sebrae" style={seloStyle} />
              </a>
              <img src={Logo} alt="Logo da sua empresa" style={logoStyle} />
            </motion.div>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{  
              marginLeft: { xs: '10%', sm: '10%', md: '0%' },  // Adiciona margem nas laterais para telas pequenas
              marginRight: { xs: '0%', sm: 0 }, // Adiciona margem nas laterais para telas pequenas
            }}>
            <Typography
              component="h2"
              variant="h4"
              color="text.primary"
              sx={{
                fontFamily: 'Rubik, sans-serif',
                fontSize: { xs: '24px', sm: '28px', md: '35px' },
                textAlign: { xs: 'left', md: 'left' },
                maxWidth: '80%',
              }}
            >
              Quem é a CyberSensei?
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                mt: 2,
                mb: { xs: 2, sm: 3 },
                fontSize: { xs: '16px', sm: '20px', md: '20px' },
                textAlign: { xs: 'justify', md: 'left',  },
                fontFamily: 'Rubik, sans-serif',
                marginBottom: '15%',
                maxWidth: '90%',
              }}
              style={{ fontFamily: 'Rubik, sans-serif' }}
            >
              A CyberSensei é uma plataforma inovadora que democratiza a segurança 
              cibernética, tornando a proteção digital acessível para empresas de todos 
              os tamanhos e segmentos. Nosso objetivo é fortalecer a cultura de segurança 
              dentro das organizações, capacitando colaboradores para identificar ameaças, 
              prevenir ataques e adotar práticas seguras no dia a dia.
              <br/><br/>
             Utilizando recursos gamificados e educativos, a CyberSensei transforma o 
             aprendizado em uma experiência envolvente e dinâmica, garantindo maior engajamento e 
             retenção do conhecimento. Com treinamentos interativos, simulações de ataques reais 
             e desafios práticos, sua equipe se torna a primeira linha de defesa cibernética, 
             reduzindo riscos e fortalecendo a segurança da informação da sua empresa.
             <br/><br/>
             💡 Prepare sua equipe para enfrentar ameaças digitais com 
             inteligência e proatividade. Conheça a CyberSensei! 🚀

            </Typography>
            <Button
              id="SaibaMais"
              style={{ fontFamily: 'Rubik, sans-serif' }}
              variant="contained"
              color="primary"
              href="https://cybersensei.com.br/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                mt: 2,
                transition: 'background-color 0.3s ease, color 0.3s ease',
                fontSize: { xs: '14px', sm: '16px' }, // Tamanho da fonte responsivo
                '&:hover': {
                  backgroundColor: '#8b0e00',
                  color: 'white',
                },
              }}
            >
              Saiba Mais
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Features;
