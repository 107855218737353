import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/system';
import Fricon from '../../assets/img/Fricon.svg';
import Diorama from '../../assets/img/Diorama.svg';
import Vila from '../../assets/img/Vila.svg';
import Hugo from '../../assets/img/HUgo.svg';
import Jose from '../../assets/img/Jose.svg';
import Fabio from '../../assets/img/Jose (1).svg';
import IconRede from '../../assets/img/RedeIcon.svg';

const userTestimonials = [
  {
    avatar: <img src={Hugo} alt="Avatar Hugo Galvão" style={{ width: '90px', height: '90px', borderRadius: '100%' }} />,
    name: 'Hugo Galvão',
    occupation: 'Supervisor de TI na Fricon',
    testimonial:
      'Sou suspeito de falar da BlackBelt IT Solutions. Sua equipe é extremamente íntegra, disciplinada, dedicada e focada nos resultados do cliente. Nosso trabalho focado na implantação da LGPD mostra o quanto a empresa está preparada para os desafios. Todas as expectativas condizem com a qualidade do serviço prestado junto a mesma. O relacionamento está além do básico, pois o time está sempre preocupado em entregar o melhor.',
  },
  {
    avatar: (
      <img src={Fabio} alt="Avatar Hugo Galvão" style={{ width: '90px', height: '90px', borderRadius: '100%' }} />
    ),
    name: 'Fábio Gomes',
    occupation: 'CTO Diorama Digital',
    testimonial:
      'Trabalhar com a Blackbelt tem sido um prazer, a flexibilidade em entender nossa situação e conseguir trazer todas as medidas, indicações e ações necessárias em uma linguagem de fácil entendimento para nós foi com certeza um diferencial. Além disso, a Blackbelt é uma empresa que com certeza assume os desafios aos quais se propõe, para nós, são pessoas da nossa equipe, não parecem funcionários externos, mas sim alguém totalmente presente.',
  },
  {
    avatar: <img src={Jose} alt="Avatar Hugo Galvão" style={{ width: '90px', height: '90px', borderRadius: '100%' }} />,
    name: 'José Luiz Silva',
    occupation: 'Gerente de TI no Vila',
    testimonial:
      'A Blackbelt se mostrou uma empresa extremamente qualificada e disposta a nos ajudar a alcançar os objetivos do projeto que desenvolvemos juntos, no caso a adequação à Lei Geral de Proteção de Dados (LGPD). Desenvolvemos um excelente trabalho nas adequações à Lei e também na parte de Segurança da Informação. Os profissionais que atuaram no projeto se mostraram muito qualificados, solícitos e com foco na entrega. Foi um sucesso.',
  },
];

const whiteLogos = [Fricon, Diorama, Vila];
const darkLogos = [Fricon, Diorama, Vila];

const logoStyle = {
  maxWidth: '30%',  // Garante que a imagem não ultrapasse o container
  height: 'auto',    // Mantém a proporção correta da imagem
  objectFit: 'contain',  // Garante que a imagem seja ajustada sem cortes
  opacity: 0.9,
};


export default function Testimonials() {
  const theme = useTheme();
  const [logo1] = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      style={{ fontFamily: 'Rubik, sans-serif' }}
      id="testimonials"
      spacing={2} 
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        gap: { xs: 3, sm: 6 },
        maxWidth: '100% !important',
        justifyContent: 'center'
      }}
    >
      <Box
  sx={{
    position: 'absolute',
    top: 0,
    right: '0%',
    width: '100%',
    height: '100%',
    marginTop: '0%',
    backgroundImage: `url(${IconRede})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    animation: 'distortEffect 8s infinite alternate', // Duração mais rápida
    zIndex: 0,
    opacity: 0.5,
    overflow: 'visible',
  }}
>
  <style>
    {`
      @keyframes distortEffect {
        0% {
          transform: scale(1) skewX(0deg) skewY(0deg) translateX(0);
        }
        50% {
          transform: scale(1.1) skewX(10deg) skewY(5deg) translateX(10%);
        }
        100% {
          transform: scale(1) skewX(0deg) skewY(0deg) translateX(0);
        }
      }
    `}
  </style>
</Box>

      <Box
        style={{ fontFamily: 'Rubik, sans-serif' }}
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
          
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          color="text.primary"
          style={{ fontFamily: 'Rubik, sans-serif', }}
          sx={{
            fontFamily: 'Rubik, sans-serif',
            fontSize: { xs: '24px', sm: '28px', md: '35px' },
            textAlign: { xs: 'center' },
          }}
        >
          Depoimentos
        </Typography>
      </Box>
      <Grid container spacing={2} style={{ fontFamily: 'Rubik, sans-serif', justifyContent: "center" }}>
        {userTestimonials.map((testimonial, index) => {
          let logoSrc;
          if (testimonial.name === 'Fábio Gomes') {
            logoSrc = Diorama;
          } else if (testimonial.name === 'José Luiz Silva') {
            logoSrc = Vila;
          } else {
            logoSrc = logo1;
          }

          return (
            
            <Grid
              item
              xs={12} // Para telas pequenas e maiores
              sm={12} // Para tablets: 1 por linha
              md={3} // Para telas maiores: 3 por linha
              key={testimonial.name}
              component={motion.div}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.3 }}
              viewport={{ once: true }}
              sx={{
                textAlign: { xs: 'left', md: 'left' },
                display: 'flex',
                justifyContent: 'center',
                width: { xs: '100%', sm: '100%', md: '100%' }, // 90% para tablet
              }}
            >
              <Card
                style={{ fontFamily: 'Rubik, sans-serif' }}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  flexGrow: 1,
                  p: 1,
                }}
              >
                <CardContent>
                  <Typography variant="body2" color="text.secondary" style={{ fontFamily: 'Rubik, sans-serif' }}>
                    {testimonial.testimonial}
                  </Typography>
                </CardContent>
                <Box
                  style={{ fontFamily: 'Rubik, sans-serif' }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    pr: 2,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Box
                        component="div"
                        sx={{
                          width: 50,
                          height: 50,
                          borderRadius: '50%',
                          overflow: 'hidden',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {testimonial.avatar}
                      </Box>
                    }
                    title={
                      <Typography
                        sx={{
                          fontFamily: 'Rubik, sans-serif',
                          fontWeight: 'bold',
                          fontSize: { xs: '15px', sm: '15px' }, // Tamanho responsivo
                          lineHeight: '1.4',
                          letterSpacing: '0.5px',
                          marginBottom: '8px',
                          whiteSpace: 'nowrap', // Impede a quebra de linha
                          overflow: 'hidden', // Impede que o texto ultrapasse
                          textOverflow: 'ellipsis', // Adiciona "..." caso o texto seja muito grande
                          maxWidth: '100%', // Garante que ocupe o máximo de espaço
                        }}
                      >
                        {testimonial.name}
                      </Typography>
                    }
                    subheader={
                      <Typography
                        sx={{
                          fontFamily: 'Rubik, sans-serif',
                          color: 'black',
                          fontSize: '15px',
                          lineHeight: '1.3',
                        }}
                      >
                        {testimonial.occupation}
                      </Typography>
                    }
                    style={{ fontFamily: 'Rubik, sans-serif' }}
                    
                  />
                  <img src={logoSrc} alt={`Logo ${index + 1}`} style={logoStyle} />
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}