import React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from '../../assets/img/logo-BlackBelt.svg';

function AppAppBar() {
  const [open, setOpen] = React.useState(false);
  const isLargeScreen = useMediaQuery('(min-width:1246px)');
  const isDrawerVisible = useMediaQuery('(max-width:902px)');

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          bgcolor: 'white',
          backgroundImage: 'none',
          height: { xs: '64px', sm: '70px', md: '10%' }, // Ajuste responsivo para a altura
          width: '100%',
          display: 'flex',
          justifyContent: 'center', // Centraliza melhor os itens
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Toolbar variant="regular" sx={{ justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', py: '8px' }}>
              <img
                src={Logo}
                alt="Logo da sua empresa"
                style={{  maxWidth: isLargeScreen ? '250px' : '150px',  cursor: 'pointer', objectFit: 'contain' }}
                className="logo"
              />
              {!isDrawerVisible && (
                <Box sx={{ display: 'flex' }}>
                  <MenuItem onClick={() => scrollToSection('Hero')} sx={{ py: '6px', px: '12px' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Rubik, sans-serif',
                      color: 'black',
                      fontSize: { xs: '12px', sm: '12px', md: '15px', lg: '20px' }, // Responsivo
                    }}
                  >
                    Home
                  </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('features')} sx={{ py: '6px', px: '12px' }}>
                    <Typography sx={{
                      fontFamily: 'Rubik, sans-serif',
                      color: 'black',
                      fontSize: { xs: '14px', sm: '12px', md: '15px', lg: '20px' }, // Responsivo
                    }}>
                      Quem Somos
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('services')} sx={{ py: '6px', px: '12px' }}>
                    <Typography sx={{
                      fontFamily: 'Rubik, sans-serif',
                      color: 'black',
                      fontSize: { xs: '14px', sm: '12px', md: '15px', lg: '20px' }, // Responsivo
                    }}>
                      Soluções
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('testimonials')} sx={{ py: '6px', px: '12px' }}>
                    <Typography sx={{
                      fontFamily: 'Rubik, sans-serif',
                      color: 'black',
                      fontSize: { xs: '14px', sm: '12px', md: '15px', lg: '20px' }, // Responsivo
                    }}>
                      Depoimentos
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('midia')} sx={{ py: '6px', px: '12px' }}>
                    <Typography sx={{
                      fontFamily: 'Rubik, sans-serif',
                      color: 'black',
                      fontSize: { xs: '14px', sm: '12px', md: '15px', lg: '20px' }, // Responsivo
                    }}>
                      Mídia
                    </Typography>
                  </MenuItem>
                </Box>
              )}
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 10, alignItems: 'center' }}>
              {!isDrawerVisible && (
                <Button
                  id="Whatsapp"
                  color="primary"
                  variant="contained"
                  component="a"
                  href="https://api.whatsapp.com/send/?phone=558184393531&text&type=phone_number&app_absent=0"
                  target="_blank"
                  startIcon={<WhatsAppIcon sx={{ width: '22px', height: 'auto', marginLeft: '15%' }} />}
                  sx={{
                    height: '30px',
                    fontSize: { xs: '16px', sm: '14px' },
                    justifyContent: 'center',  // Alinha o conteúdo horizontalmente
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: '#8b0e00',
                      color: 'white',
                    },
                  }}
                />
              )}
            </Box>

            {/* Icone de Menu e Drawer (Menu lateral) para telas menores que md */}
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <Button variant="text" color="primary" onClick={() => setOpen(true)}>
                <MenuIcon sx={{ color: 'black' }} /> {/* Ícone Preto */}
              </Button>
              <Drawer
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
                sx={{
                  '& .MuiDrawer-paper': {
                    backgroundColor: 'white',
                    color: 'black',
                  },
                }}
              >
        <Box sx={{ minWidth: '60dvw', p: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', fontFamily: 'Rubik, sans-serif' }}>
            {[
              { label: 'Home', section: 'Hero' },
              { label: 'Quem Somos', section: 'features' },
              { label: 'Soluções', section: 'services' },
              { label: 'Depoimentos', section: 'testimonials' },
              { label: 'Mídia', section: 'midia' },
            ].map((item) => (
              <MenuItem key={item.label} onClick={() => scrollToSection(item.section)}>
                 <Typography
                  sx={{
                    fontFamily: 'Rubik, sans-serif',
                    fontSize: '16px', // Tamanho ajustável
                    color: 'black',
                  }}
                >
                  {item.label}
          </Typography>
              </MenuItem>
            ))}
              <Divider />
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="contained"
                        component="a"
                        href="https://api.whatsapp.com/send/?phone=558184393531&text&type=phone_number&app_absent=0"
                        target="_blank"
                        startIcon={<WhatsAppIcon sx={{ width: '22px', height: '22px' }} />}
                        sx={{
                          height: '30px',
                          fontSize: { xs: '16px', sm: '14px' },
                          fontFamily: 'Rubik, sans-serif',
                          '&:hover': {
                            backgroundColor: '#8b0e00',
                            color: 'white',
                          },
                        }}
                      >
                        WhatsApp
                      </Button>
                    </MenuItem>
                  </Box>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </div>
  );
}

export default AppAppBar;
