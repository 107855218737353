import React from 'react';
import { Grid, Card, CardContent, Typography, Button, Box } from '@mui/material';
import Servicos from '../../assets/img/Opção2.jpg';

function CallToAction() {
  return (
    <Box
      sx={{
        display: 'flex',
        zIndex: '4 !important',
        flexDirection: 'column', // Organizar o conteúdo na vertical
        minHeight: { xs: '70vh', sm: '100vh' }, // Garantir que ocupe 100% da altura da tela
        background: 'linear-gradient(to bottom, white 50%, black 50%)', // Gradiente de fundo
        position: 'relative', // Necessário para posicionar a imagem
      }}
    >
      {/* Área com a imagem de fundo centralizada */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 0, // Coloca a imagem abaixo do conteúdo
          width: '100%', // Ajuste a largura para cobrir toda a tela
          height: '90%', // Ajuste a altura para cobrir toda a tela
          backgroundImage: `url(${Servicos})`,
          backgroundSize: 'contain', // Ajusta a imagem dentro do contêiner
          backgroundRepeat: 'no-repeat', // Evita que a imagem se repita
          backgroundPosition: 'center', // Centraliza a imagem
        }}
      />
      
      {/* Área superior com fundo branco */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1, // Garantir que o conteúdo ocupe metade da altura
          padding: { xs: '20px', sm: '50px' },
        }}
      >
        {/* Conteúdo superior aqui */}
      </Box>

      {/* Área inferior com fundo preto e o card posicionado à direita */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end', // Posicionar o card à direita
          alignItems: 'center',
          flex: 1, // Garantir que o conteúdo ocupe a outra metade da altura
          padding: { xs: '20px', sm: '50px' },
          zIndex: 2, // Coloca a imagem abaixo do conteúdo
        }}
      >
        <Grid container spacing={5} justifyContent="flex-end" alignItems="center">
          <Grid item xs={12} md={5}>
            <Card
              sx={{
                maxWidth: 445,
                backgroundColor: '#f4f4f4', // Cor de fundo do card
                color: '#8b0e00', // Texto preto dentro do card
                padding: '20px',
                boxShadow: '0 8px 15px rgba(0, 0, 0, 0.2)',
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Rubik, sans-serif',
                    color: '#8b0e00',
                    marginBottom: '15px',
                    fontSize: { xs: '24px', sm: '28px', md: '35px' },
                  }}
                >
                  Começando
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: 'Rubik, sans-serif',
                    color: '#8b0e00', // Cor do texto
                    marginBottom: '20px',
                    fontSize: { xs: '16px', sm: '20px', md: '20px' },
                  }}
                >
                  Proteja sua Empresa com Segurança de Alto Nível 🔐
                  Entre em contato conosco hoje mesmo e agende uma consulta 
                  gratuita para entender como podemos fortalecer a segurança da 
                  sua empresa.
                  <br/><br/>
                  📌 Como preferir, no seu tempo:
                  ✔ Reunião presencial em sua empresa.
                  ✔ Videoconferência com nossos especialistas.
                  ✔ Chamada telefônica rápida e objetiva.
                  <br/><br/>
                  💡 Sem compromisso, apenas segurança paraoseunegócio
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  component="a"
                  fullWidth
                  href="https://api.whatsapp.com/send/?phone=558184393531&text&type=phone_number&app_absent=0"
                  target="_blank"
                  sx={{
                    fontFamily: 'Rubik, sans-serif',
                    backgroundColor: '#8b0e00',
                    '&:hover': {
                      backgroundColor: '#8b0e00',
                    },
                  }}
                >
                  Marcar Consulta
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default CallToAction;
