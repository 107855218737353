import React from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import EntidadesdeSaude from '../../assets/img/EntidadesdeSaúde.png';
import IndustriaEngenharia from '../../assets/img/IndústriaeEngenharia.png';
import EntretenimentoLazer from '../../assets/img/EntretenimentoeLazer.png';
import PublicidadePropaganda from '../../assets/img/PublicidadeePropaganda.png';
import Servicos from '../../assets/img/Serviços.png';
import Ensino from '../../assets/img/ensino.png';
import EmpresasTecnologia from '../../assets/img/EmpresasDeTecnologia.png';
import IconRede from '../../assets/img/RedeIcon.svg';
import InstituiçõesGovernamentais from '../../assets/img/InstituiçõesGovernamentais.png';

const Container = styled.div`
  display: flex;
  height: auto; // Permite que cresça conforme o conteúdo
  min-height: 100vh; // Mantém pelo menos o tamanho da tela
  position: relative;
  flex-direction: column;

  @media (max-width: 768px) {
    min-height: 100vh; // Aumenta a altura em dispositivos móveis
  }

  @media (min-width: 1120px) {
    flex-direction: row;
    min-height: 100vh; // Mantém a altura normal em telas grandes
  }
`;

const LeftSide = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  @media (min-width: 1120px) {
    width: 40%;
  }
`;

const RightSide = styled.div`
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: -10px; // Ajuste para evitar sobreposição
  min-height: 100vh; // Define um tamanho mínimo responsivo

  @media (max-width: 768px) {
    padding-top: 20%; // Ajuste para dispositivos móveis
    min-height: auto;
  }
  @media (max-width: 364px) {
    padding-top: 40%; // Ajuste para dispositivos móveis
    min-height: auto;
  }
  @media (max-width: 354px) {
    padding-top: 50%; // Ajuste para dispositivos móveis
    min-height: auto;
  }

  @media (max-width: 820px) {
    padding-top: 20%; // Ajuste para dispositivos móveis
    min-height: auto;
  }
  @media (max-width: 1024px) {
    padding-top: 20%; // Ajuste para dispositivos móveis
    min-height: auto;
  }
  @media (min-width: 1120px) {
    width: 60%;
    min-height: 100vh;
  }
`;

const CarouselContainer = styled.div`
  width: 150%;
  position: relative;
  z-index: 0;
  margin-top: 20px;
`;

const imageData = [
  { src: EntidadesdeSaude, label: "Entidades de Saúde" },
  { src: IndustriaEngenharia, label: "Indústria e Engenharia" },
  { src: EntretenimentoLazer, label: "Entretenimento e Lazer" },
  { src: PublicidadePropaganda, label: "Publicidade e Propaganda" },
  { src: Servicos, label: "Serviços (Advocatícios, Contábeis, Funerários)" },
  { src: EmpresasTecnologia, label: "Empresas de Tecnologia" },
  { src: Ensino, label: "Instituições de Ensino" },
  { src: InstituiçõesGovernamentais, label: "Instituições Governamentais" }
];

const App = () => {
  return (
    <Container id='features'>
      <LeftSide>
        <Typography
          component="h2"
          variant="h4"
          color="text.primary"
          sx={{
            fontFamily: 'Rubik, sans-serif',
            fontSize: { xs: '24px', sm: '28px', md: '35px' },
            textAlign: { xs: 'left', md: 'left' },
          }}
        >
          Quem somos
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            mt: 2,
            mb: 3,
            fontSize: { xs: '16px', sm: '20px', md: '20px' },
            textAlign: 'justify',
            fontFamily: 'Rubik, sans-serif',
            maxWidth: '70%',
          }}
        >
          Somos uma empresa especializada em serviços, soluções e treinamentos em Segurança da
              Informação e Privacidade de Dados, com uma presença abrangente em todo o território
              nacional. Nossa ampla experiência e parcerias estratégicas com renomados fabricantes
              mundiais nos permite oferecer um conjunto diversificado de recursos e conhecimentos
              para proteger seu negócio de maneira eficaz e resiliente.
        </Typography>
      </LeftSide>

      <RightSide>
        <Box sx={{ position: 'absolute', top: '10%', zIndex: 10, width: '100%', textAlign: 'center' }}>
          <Typography
            component="h4"
            variant="h5"
            color="white"
            sx={{
              fontFamily: 'Rubik, sans-serif',
              fontSize: { xs: '24px', sm: '28px', md: '35px' },
              marginBottom: 1,
              fontWeight: 'normal',
            }}
          >
            Quem ajudamos e onde atuamos
          </Typography>
        </Box>
        <Box
        id='Features'
          sx={{
            position: 'absolute',
            top: 0,
            right: '0%',
            width: '100%',
            height: '100%',
            marginTop: '10%',
            backgroundImage: `url(${IconRede})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            animation: 'distortEffect 15s infinite alternate',
            zIndex: 0,
            opacity: 0.5,
            overflow: 'visible',
          }}
        />
        <style>
          {`
            @keyframes distortEffect {
              0% {
                transform: scale(1) skewX(0deg) skewY(0deg);
              }
              50% {
                transform: scale(1.05) skewX(5deg) skewY(3deg);
              }
              100% {
                transform: scale(1) skewX(0deg) skewY(0deg);
              }
            }
          `}
        </style>

        <CarouselContainer>
          <Carousel autoPlay infiniteLoop showThumbs={false} showArrows={false} showStatus={false} interval={5000}>
            {imageData.map((item, index) => (
              <div key={index} style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src={item.src} alt={item.label}style={{
                      width: '50%', // Ajusta a largura para cobrir toda a área
                      objectFit: 'cover', // Garantir que a imagem cubra toda a área sem distorção
                      transition: 'opacity 0.8s ease-in-out', // Transição suave da opacidade
                      zIndex: -1, // Garante que a imagem fique atrás do texto
                    }} />
                
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%', // Centraliza verticalmente
                    left: '50%', // Centraliza horizontalmente
                    transform: 'translate(-50%, -50%)', // Ajusta a posição para o centro exato
                    backgroundColor: 'black', // Fundo preto
                    border: '0.1px solid red', // Borda vermelha
                    color: 'white',
                    padding: '10px',
                    borderRadius: '15px',
                    zIndex: 1, // Garante que o texto fique sobre a imagem
                    textAlign: 'center',
                    boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.6), 0px 12px 12px rgba(0, 0, 0, 0.4)',
                  }}
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    sx={{
                      fontFamily: 'Rubik, sans-serif',
                      fontSize: { xs: '18px', sm: '20px', md: '22px' },
                    }}
                  >
                    {item.label}
                  </Typography>
                </Box>
              </div>
            ))}
          </Carousel>
        </CarouselContainer>
      </RightSide>
    </Container>
  );
};

export default App;
