import React from 'react';
import { Container, Grid, Box, Typography, Button } from '@mui/material';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import IconA from '../../assets/img/IconA.svg';
import IconB from '../../assets/img/IconB.svg';
import IconC from '../../assets/img/IconC.svg';
import IconRede from '../../assets/img/RedeIconA.svg';

const services = [
  {
    title: 'SEGURANÇA DA INFORMAÇÃO',
    description:
      'Possuímos um conjunto abrangente de serviços, soluções e treinamentos que visa auxiliar sua empresa a alcançar o mais alto nível de Segurança da Informação. Com nossa equipe multidisciplinar, estamos preparados para atender às diversas demandas e projetos no contexto da cibersegurança, contribuindo para tornar seu negócio mais resiliente e sustentável.',
    imageUrl: IconA,
    buttonText: 'Saiba Mais',
    link: 'https://drive.google.com/file/d/1cYtCPoC63IdWS4cSbdyjwSlx8tXbIoQp/view'
  },
  {
    title: 'PRIVACIDADE E GOVERNANÇA DE DADOS',
    description:
      'Nossa principal missão consiste em auxiliar sua empresa a cumprir todos os requisitos legais relacionados ao compliance da LGPD. Por meio de uma abordagem dinâmica e autônoma, nossa equipe trabalha em conjunto com o seu negócio para desenvolver modelos que o tornam mais competitivo no mercado, ao mesmo tempo em que evitam problemas com sanções oriundas de órgãos fiscalizadores.',
    imageUrl: IconB,
    buttonText: 'Saiba Mais',
    link: 'https://drive.google.com/file/d/12qsDxFaM5gSzDAIkJTds1H_f32-LldMC/view'
  },
  {
    title: 'BBIT CLOUD SERVICES',
    description:
      'Nosso time de Cloud Services tem como principal objetivo fornecer suporte abrangente para sua empresa em todos os aspectos relacionados à implantação e manutenção de infraestruturas de Cloud Computing. Com uma abordagem especializada, estamos prontos para oferecer soluções personalizadas de Cloud Deployment, Gestão Financeira (FinOps) e Automação e Segurança de Estruturas (DevSecOps).',
    imageUrl: IconC,
    buttonText: 'Saiba Mais',
  },
];

function Services() {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  return (
    <Container
      id="services"
      ref={ref}
      sx={{
        zIndex: '1 !important',
        py: { xs: 7, sm: 10 },
        backgroundColor: 'black',
        maxWidth: '100% !important',
        padding: 0,
        minHeight: '100vh',
        position: 'relative',
        
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8, ease: 'easeOut' }}
      >
        <Typography
          component="h2"
          variant="h4"
          sx={{
            fontFamily: 'Rubik, sans-serif',
            textAlign: 'center',
            marginBottom: '40px',
            color: 'white',
             fontSize: {xs: '24px', sm: '28px', md: '35px'},
          }}
        >
          
          Nossos serviços e soluções
        </Typography>
      </motion.div>

      <Grid container spacing={3} sx={{
          margin: '0 auto', // Centraliza horizontalmente
          width: '85%',
          display: 'flex',
          justifyContent: 'center', // Alinha os itens ao centro
          alignItems: 'center', // Alinha os itens verticalmente
          textAlign: 'center', // Centraliza o texto
        }}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={12} md={4} key={index} >
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.8, delay: index * 0.2 }}
            >
              <Box
                sx={{
                  backgroundColor: 'black',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  boxShadow: 3,
                  padding: 0,
                  borderRadius: 2,
                  color: 'white',
                  marginLeft: { xs: '-10%', sm: '0%', md: '0%' },  // Adiciona margem nas laterais para telas pequenas
                  marginRight: { xs: '0%', sm: 0 }, // Adiciona margem nas laterais para telas pequenas
                }}
              >
                <img
                  src={service.imageUrl}
                  alt={service.title}
                  style={{
                    width: '30%',
                    height: 'auto',
                    marginBottom: '20px',
                  }}
                />
                <Typography
                  component="h3"
                  variant="h5"
                  sx={{
                    fontFamily: 'Rubik, sans-serif',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    color: 'white',
                    maxWidth: { xs: '80%', md: '80%' }
                  }}
                >
                  {service.title}
                </Typography>

                {/* Linha abaixo do título */}
                <Box
                  sx={{
                    width: '100%',
                    height: '4px',
                    backgroundColor: '#ff0000',
                    marginBottom: '20px',
                    maxWidth: { xs: '80%', sm: '80%', md: '80%' }
                  }}
                />

                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: '16px', sm: '18px' },
                    marginBottom: '20px',
                    color: 'white',
                    textAlign: 'justify',
                    maxWidth: { xs: '80%', md: '80%' },
                    hyphens: 'auto',
                  }}
                >
                  {service.description}
                </Typography>
                <Button
                  id="SaibaMais"
                  style={{ fontFamily: 'Rubik, sans-serif', zIndex: '2' }}
                  variant="contained"
                  color="primary"
                  href={service.link} // Usa o link específico da seção
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    mt: 2,
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                    fontSize: { xs: '14px', sm: '16px' },
                    '&:hover': {
                      backgroundColor: '#8b0e00',
                      color: 'white',
                    },
                  }}
                >
                  {service.buttonText}
                </Button>
              </Box>
            </motion.div>
          </Grid>
        ))}
      </Grid>

      {/* Imagem de fundo inclinada */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
          height: '100%',
          marginTop: '20%',
          backgroundImage: `url(${IconRede})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          animation: 'distortEffect 8s infinite alternate', // Acelerei a animação
          zIndex: 0,
          transform: 'rotate(-10deg)',
          opacity: 0.2,
          overflow: 'hidden',
        }}
      >
        <style>
          {`
            @keyframes distortEffect {
              0% {
                transform: scale(1) skewX(0deg) skewY(0deg);
              }
              50% {
                transform: scale(1.1) skewX(10deg) skewY(6deg);  // Distorção mais intensa
              }
              100% {
                transform: scale(1) skewX(0deg) skewY(0deg);
              }
            }
          `}
        </style>
      </Box>

    </Container>
  );
}

export default Services;
