import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ArrowForward from '@mui/icons-material/ArrowForward';
import PropTypes from 'prop-types';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import IconRede from '../../assets/img/RedeIconA.svg';

const stats = [
  { icon: <HandshakeIcon sx={{ color: 'white', backgroundColor: '#ae1100', borderRadius: '50%', padding: '10px', fontSize: '70px' }} />, label: 'Empresas', value: '500+' },
  { icon: <ThumbUpIcon sx={{ color: 'white', backgroundColor: '#ae1100', borderRadius: '50%', padding: '10px', fontSize: '70px' }} />, label: ' De recomendação entre nossos clientes', value: '97%' },
  { icon: <BusinessCenterIcon sx={{ color: 'white', backgroundColor: '#ae1100', borderRadius: '50%', padding: '10px', fontSize: '70px' }} />, label: 'Anos de experiência', value: '15+' },
];

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div
      role="button"
      tabIndex={0}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#ae1100',
        borderRadius: '50%',
        padding: '10px',
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 1,
      }}
      onClick={onClick}
      onKeyPress={onClick}
      aria-label="Próximo"
    >
      <ArrowForward style={{ color: 'white' }} />
    </div>
  );
}

NextArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default function LogoCollection() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Monitorando o aparecimento da seção na tela com o IntersectionObserver
    const section = document.getElementById('logoCollection');
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Quando 50% da seção for visível
    );
    observer.observe(section);

    return () => observer.disconnect();
  }, []);

  return (
    <Box
      id="logoCollection"
      sx={{ py: 15, position: 'relative', backgroundColor: 'black', zIndex: '2' }}
    >
      {/* Box principal */}
      <Box
        sx={{
          backgroundColor: 'white',
          padding: { xs: '20px', sm: '30px' }, // Ajuste dinâmico do padding
          borderRadius: '8px',
          maxWidth: '1200px',
          margin: '0 auto',
        }}
      >
        {/* Descrição */}
        <Typography
          component="p"
          variant="subtitle2"
          align="center"
          color="text.secondary"
          sx={{
            fontFamily: 'Rubik, sans-serif',
            color: '#ae1100',
            fontSize: { xs: '20px', sm: '28px', md: '35px' },
          }}
        >
          Razões pra escolher a BlackBelt
        </Typography>

        {/* Fundo com a imagem */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${IconRede})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            animation: 'distortEffect 15s infinite alternate',
            zIndex: -1,
            transform: 'rotate(-10deg)',
            opacity: 0.2,
          }}
        />

        <style>
          {`
            @keyframes distortEffect {
              0% {
                transform: scale(1) skewX(0deg) skewY(0deg);
              }
              50% {
                transform: scale(1.05) skewX(5deg) skewY(3deg);
              }
              100% {
                transform: scale(1) skewX(0deg) skewY(0deg);
              }
            }

            .fade-in {
              opacity: 0;
              transform: translateY(20px);
              transition: opacity 1s, transform 1s;
            }

            .fade-in.visible {
              opacity: 1;
              transform: translateY(0);
            }

            .fade-in:nth-of-type(1) {
              transition-delay: 0.2s;
            }

            .fade-in:nth-of-type(2) {
              transition-delay: 0.4s;
            }

            .fade-in:nth-of-type(3) {
              transition-delay: 0.6s;
            }
          `}
        </style>

        {/* Seção dos cards */}
        <Box
          sx={{
            py: 10,
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' }, // Responsividade
            gap: { xs: 3, sm: 4, md: 5 },
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {stats.map((stat, index) => (
            <Box
              key={index}
              sx={{
                maxWidth: 250,
                margin: '0 auto',
                transition: 'opacity 1s, transform 1s', // Transição suave
              }}
              className={`fade-in ${isVisible ? 'visible' : ''}`} // Aplica a classe visível quando a seção aparece
            >
              {stat.icon}
              <Typography variant="h4" sx={{ fontWeight: 'bold', mt: 1, fontFamily: 'Rubik, sans-serif' }}>
                {stat.value}
              </Typography>
              <Typography variant="subtitle1" sx={{ mt: 1, fontFamily: 'Rubik, sans-serif', fontSize: { xs: '18px', sm: '22px', md: '22px' } }}>
                {stat.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
