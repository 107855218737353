import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Box, useMediaQuery } from '@mui/material';
import Tamb from '../../assets/img/tambPodCast.jpg';
import { motion } from 'framer-motion';
import Quarteto from '../../assets/img/quartetoFantastico.jpg';
import IconRede from '../../assets/img/RedeIconA.svg';
import NovoCardImage1 from '../../assets/img/_LGPD.svg'; // Imagem do novo card
import NovoCardImage2 from '../../assets/img/SummitGl.jpg'; // Imagem do novo card

const mediaItems = [
  {
    id: 1,
    title: 'Startup de professor da Unit-PE é uma das maiores do Brasil',
    description: 'A empresa de cibersegurança é a única pernambucana no top 30 nacional',
    imageUrl: Quarteto,
    link: 'https://pe.unit.br/blog/noticias/startup-de-professor-da-unit-pe-e-uma-das-maiores-do-brasil/',
  },
  {
    id: 2,
    title: 'BlackBelt IT Solutions e Segurança da Informação',
    description:
      'Somos Faixa Preta em Segurança da Informação, Privacidade e Governança de Dados (LGPD). Quer entender mais? Assista o podcast!',
    imageUrl: Tamb,
    imageStyle: { marginTop: '-20px' },
    link: 'https://www.youtube.com/watch?v=dJ5U78EoEvw&t=2s',
  },
  {
    id: 3,
    title: 'Empresas do Nordeste enfrentam desafios para adequação à LGPD',
    description: 'Em 2024, cerca de 30% dos usuários brasileiros relataram violações de dados pessoais, segundo a Federação Brasileira de Bancos (Febraban).',
    imageUrl: NovoCardImage1,
    link: 'https://movimentoeconomico.com.br/internet/2025/01/29/empresas-do-ne-enfrentam-desafios-para-adequacao-a-lgpd/',
  },
  {
    id: 4,
    title: 'Startup Summit: conheça a BlackBelt IT Solutions',
    description:
      'A BlackBelt IT Solutions, empresa de Pernambuco especializada em consultoria, soluções e treinamentos em segurança da informação e privacidade de dados',
    imageUrl: NovoCardImage2,
    imageStyle: { marginTop: '-20px' },
    link: 'https://economiasc.com/2024/07/31/startup-summit-conheca-a-blackbelt-it-solutions-especialista-em-seguranca-da-informacao-e-privacidade-de-dados/',
  },
];

function MediaPage() {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  return (
    <Box
      id="midia"
      sx={{
        backgroundColor: 'black', // Fundo preto
        color: 'white', // Texto branco para contraste
        pt: { xs: 4, sm: 12 },
        pb: { xs: 5, sm: 7 },
        zIndex: '3 !important',
        maxWidth: '100% !important',
        minHeight: '90vh',
        position: 'relative',
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        style={{ fontFamily: 'Rubik, sans-serif' }}
        sx={{
          fontSize: { xs: '24px', sm: '28px', md: '35px' },
          textAlign: { xs: 'center' },
          color: 'white', // Texto branco
        }}
      >
        BlackBelt na Mídia
      </Typography>
      <Grid container spacing={7} justifyContent="center" sx={{ padding: '50px 40px' }}>
        {mediaItems.map((item) => (
          <Grid
            item
            xs={12} // Para telas pequenas e maiores
            sm={6}  // Para tablets: 2 por linha
            md={2.8}  // Para telas maiores que 850px, ocupa 1/4 da tela
            key={item.id}
            component={motion.div}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: item * 0.3 }}
            viewport={{ once: true }}
            sx={{
              textAlign: { xs: 'left', md: 'center' },
              display: 'flex',
              justifyContent: 'center',
              width: '100%', // Garantir que o item ocupe toda a largura
            }}
          >
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', fontFamily: 'Rubik, sans-serif' }}
            >
              <Card sx={{ height: '380px', width: '100%', margin: '0px', backgroundColor: '#222' }}>
                <CardMedia
                  component="img"
                  height="150"
                  image={item.imageUrl}
                  alt={item.title}
                  style={{ objectFit: 'cover', ...item.imageStyle, }}
                />
                <CardContent
                  sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '230px' }}
                >
                  <Typography variant="h5" component="div" sx={{ color: 'white', fontFamily: 'Rubik, sans-serif',  fontSize: '14px' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#bbb', marginTop: '5%' }}>
                    {isSmallScreen ? `${item.description.slice(0, 50)}...` : item.description}
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
          height: '100%',  // Ajuste a altura para garantir que a imagem ocupe toda a tela
          marginTop: '10%',
          backgroundImage: `url(${IconRede})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          animation: 'distortEffect 15s infinite alternate',
          zIndex: -4,
          transform: 'rotate(-10deg) translateX(5%)', // Inclinação da imagem
          opacity: 0.2,
          overflow: 'hidden', // Garantir que a imagem seja cortada se ultrapassar os limites
        }}
      />
      <style>
        {
          `
          @keyframes distortEffect {
            0% {
              transform: scale(1) skewX(0deg) skewY(0deg);
            }
            50% {
              transform: scale(1.05) skewX(5deg) skewY(3deg);
            }
            100% {
              transform: scale(1) skewX(0deg) skewY(0deg);
            }
          }
          `
        }
      </style>
    </Box>
  );
}

export default MediaPage;
